import React from 'react'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import AuthorExpanded from '@widgets/AuthorExpanded/AuthorExpanded'
import { useStaticQuery , graphql } from 'gatsby'

const authorinfo = graphql`
  query authorquery {
    authorInfo: articleAuthor(slug: { eq: "/author/babu-srinivasan/" }) {
      ...ArticleAuthor
    }
  }
`

const AboutPage = (props) => {
    const { authorInfo } = useStaticQuery(authorinfo)

    return (
        <Layout {...props}>
            <Seo title='About Me' />
            <Stack>
            <Main>
                {/* <PageTitle
                    header="About Me"
                /> */}
                <Divider />
                <AuthorExpanded author={authorInfo} withLink={false}/>
            </Main>
            </Stack>
        </Layout>
    )
}

export default AboutPage